/* copied from index.module.css */

/* @font-face {
    font-family: "ColfaxAI";
    src: url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff2)
            format("woff2"),
        url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff)
            format("woff");
    font-weight: normal;
    font-style: normal;
} */
@font-face {
    font-family: "ColfaxAI";
    src: url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff2)
            format("woff2"),
        url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff)
            format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "ColfaxAI";
    src: url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff2)
            format("woff2"),
        url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff) format("woff");
    font-weight: bold;
    font-style: normal;
}

.main,
.main input {
    font-size: 16px;
    line-height: 24px;
    color: #353740;
    font-family: "ColfaxAI", Helvetica, sans-serif;
}
.main {
    padding-top: 60px;
}
.main .icon {
    width: 50px;
}
.main h3 {
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
    color: #202123;
    margin: 16px 0 40px;
}
.main form {
    display: flex;
    flex-direction: column;
    width: 320px;
}
.main input[type="text"] {
    padding: 12px 16px;
    border: 1px solid #10a37f;
    border-radius: 4px;
    margin-bottom: 24px;
    outline-color: #10a37f;
}
.main ::placeholder {
    color: #8e8ea0;
    opacity: 1;
}
.main input[type="submit"] {
    padding: 12px 0;
    color: #fff;
    background-color: #10a37f;
    border: none;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.main .flexLayout {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.main .leftColumn {
    flex: 1 1 50%;
    max-width: 50%;
    box-sizing: border-box;
    padding-right: 2vw;
    padding-left: 2vw;
    border-right: 1px solid black;
}

.main .editFlexLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.main .editTwoButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 40vw;
    min-width: 400px;
    margin-bottom: 5vh;
}

.main .result {
    font-weight: bold;
    font-size: 11px;
    color: dimgrey;
    outline-color: black;
}

.main .copyButton {
    width: auto;
    text-align: center;
    background-color: dimgrey;
    align-self: end;
    border: 1px;
    cursor: pointer;
    color: #fff;
    padding: 5px;
}

.main .codeBlockHeader {
}

.main .codeBlockSmall {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-top: 20px;
    width: auto;
    background: lightgray;
    border: none;
    padding: 10px;
    margin: 10px;
}

.main .codeBlock {
    border-radius: 10px;
    margin-top: 20px;
    width: auto;
    background: lightgray;
    /* border: solid 1px; */
    border: none;
    padding: 50px;
    margin: 10px;
}

.main .masterPromptBlock {
    color: black;
    font-size: 20px;
    text-align: left;
    margin-left: 40px;
    flex-grow: 1;
}

.main .mathpixHelperBlock {
    color: black;
    font-size: 20px;
    text-align: left;
    margin-left: 40px;
    flex-grow: 1;
}

.main .mathpixResultBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    color: black;
}

.main .editPromptCode {
    width: 40vw;
    height: 80vh;
    min-width: 400px;
    max-height: 550px;
}

.main pre code {
    display: block;
    background: none;
    white-space: pre;
    max-width: 100%;
    min-width: 100px;
    padding: 0;
    font-size: 12px;
    white-space: pre-wrap;
}

.main textarea {
    width: 700px;
    height: 150px;
}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #e61e28;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.main .test {
    background: white;
    border: solid 1px;
    /* color:white; */
}

.main .mathpix {
    width: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-size: 12px;
    border: 1px dashed black;
    margin: 5px;
    color: black;
	max-height: 150px;
	overflow: auto;
}

.main .editBlockTitle{
	color:#e61e28;
	text-align: center;
}

.main .editMathpixResultBlock{
	padding: 0 5px;
}

.main .editMathpixResultText{
	width:100%;
	color:black;
	background-color: white;
	max-height: 150px;
	
}

.main .fileSelect {
    display: flex;
    flex-direction: column;
    width: 320px;
    padding: 0px 0px 30px;
}

/* Hide the original text */
.main .fileInput {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 0px;
    height: 0px;
}

/* Button style for 'Upload', 'Revert' and 'Create' */
.main .basicButton {
    width: 70px;
    height: 30px;
    background-color: #202020;
    color: #fff;
    border: none;
    border-radius: 5px;
	font-size: 16px;
}

.main .createButton{
	width: 70px;
    height: 30px;
    background-color: #202020;
    color: #fff;
    border: none;
    border-radius: 5px;
	align-self: flex-start;
	margin-top: 4px;
	margin-left: 6px;
	background-color: #e61e28;
	color: white;
	border:none;

}

.main .markdownContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.main .rightColumn{
	flex:1 1 50%;
	max-width: 50%;
	box-sizing: border-box;
	padding-left: 30px;
	padding-right: 50px;
}

.main .rightColumnTitle{
	position:relative;
	padding-left: 30px;
	color: #e61e28;
	text-align: center;
}

.main .moduleSelection{
	display: flex;
	justify-content: center;
	align-items: center;
}

.container {
    display: flex;
    height: 100vh;
    overflow: hidden; 
}

.navigation {
    width: 300px; 
    background-color: #f0f0f0; 
    height: calc(100% - 50px);
    margin-top: 50px; 
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto; 
    border-right: 2px solid grey; 
    position: fixed; 
    top: 0; 
    left: 0;
    z-index: 1; 
}

.mainContent {
    margin-left: 350px; 
    padding-top: 60px; 
    padding-bottom: 20px;
    overflow-y: auto; 
    height: 100vh; 
    width: calc(100% - 200px); 
}

.navigation ul {
    list-style: none;
    font-size: large;
}

.navigation li a {
    text-decoration: none;
    color: black;
}

.navigation li a:hover {
    text-decoration: double;
    color:red;
}

.navigation li ul {
    font-size: medium;
}

.navigation li ul li ul{
    font-size: smaller;
}

.scrollTarget {
    scroll-margin-top: 70px; /* Use the actual height of your app bar */
}